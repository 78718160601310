<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="parent_page">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name" v-model="verify_user.name" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="First Name" v-model="verify_user.firstname" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Last Name" v-model="verify_user.lastname" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Birthday" v-model="verify_user.birthday" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Country" v-model="verify_user.country_name" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="City" v-model="verify_user.city" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Address" v-model="verify_user.address" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Postalcode" v-model="verify_user.postalcode" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Document Type" v-model="verify_user.document_name"
                                          disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Verified" :value="verifyAt" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Created" :value="createdAt" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Updated" :value="updatedAt" disabled></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="ml-0 mr-0 item mt-2" no-gutters align="center">
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>Photo of user ID</v-card-title>
                                <v-card-text>
                                    <show-private-document
                                        :src-image="verify_user.photo_id_src"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>Photo of holding your ID</v-card-title>
                                <v-card-text>
                                    <show-private-document
                                        :src-image="verify_user.photo_holding_id_src"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn class="warning ml-4 mt-6" @click="handleVerify">Verify</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout"
import moment from "moment"
import {mapMutations} from "vuex"
import VerifyUsers from "@/models/admin/VerifyUsers"
import ShowPrivateDocument from "@/components/admin/ShowPrivateDocument";

export default {
    name: "documents-type-show",
    components: {
        AdminDashboardLayout,
        ShowPrivateDocument,
    },
    data: function () {
        return {
            parent_page: {name: 'admin.verify-users.index'},
            verify_user: {},
            errors: {},
        }
    },
    computed: {
        verifyAt() {
            return this.verify_user.verify_at ? moment(this.verify_user.verify_at).format('LLL') : ''
        },
        createdAt() {
            return this.verify_user.created_at ? moment(this.verify_user.created_at).format('LLL') : ''
        },
        updatedAt() {
            return this.verify_user.updated_at ? moment(this.verify_user.updated_at).format('LLL') : ''
        },
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        handleVerify() {
            this.verify_user.verify = true
            this.verify_user.save().then(async () => {
                this.errors = {}
                this.verify_user = await VerifyUsers.find(this.$route.params.id)
                this.showSnackBar(this.$t('messages.success'))
            }).catch((e) => {
                this.errors = e.response.data.errors
            })
        },
    },
    async mounted() {
        this.verify_user = await VerifyUsers.find(this.$route.params.id)
    },
}
</script>

<style lang="scss">
</style>
