<template>
    <v-row no-gutters>
        <v-col cols="12">
            <div v-if="previewImage" class="preview-image">
                <v-img ref="image" :src="previewImage" @click="viewFullImage = true"/>
                <v-dialog
                    v-model="viewFullImage"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                    scrollable
                >
                    <v-card>
                        <v-card-title class="text-h5">
                            {{ $t('components.preview_document') }}
                        </v-card-title>

                        <v-card-text>
                            <v-img full-width :src="previewImage" />
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="viewFullImage = false"
                            >
                                {{ $t('components.close') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </v-dialog>
            </div>
            <div v-else class="add-file" @click="$refs[id].click()">empty</div>
        </v-col>
    </v-row>
</template>

<script>

import PrivateImage from "@/models/admin/PrivateImage";

export default {
    name: "ShowPrivateDocument",
    data(){
        return {
            viewFullImage: false,
            previewImage: null
        }
    },
    props: {
        srcImage: {
            type: String,
        }
    },
    watch: {
        async srcImage(val) {
            if (val) {
                PrivateImage.find(val).then((response) => {
                    this.previewImage = response.data
                });
            }
        }
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.add-file, .preview-image{
    border:2px dashed white;
    width: 90px;
    height:90px;
    font-size: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 11px;
    background: rgba(237,237,237,0.09);
}

.preview-image {
    height: auto;
}
</style>
